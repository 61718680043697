import React, { useMemo, useState, useEffect  } from "react";
import { useTable, useSortBy, useGlobalFilter, getFilteredRowModel} from "react-table";
import "./TableDoklady.css";
import GlobalFilter from "../_components/GlobalFilter"
import { FaPrint } from "react-icons/fa";
import { FaWrench } from "react-icons/fa";
import { SiMicrosoftexcel } from "react-icons/si";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/cs';
import dayjs from 'dayjs';
import { authHeader } from '../_helpers';
import _ from "lodash";
import Tooltip from '@mui/material/Tooltip';
import * as XLSX from 'xlsx/xlsx.mjs';



const TableArchiv = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [doklad, setDoklad] = useState();
  const [datumOd, setDatumOd] = useState();
  const [datumDo, setDatumDo] = useState();
  const user = JSON.parse(localStorage.getItem('user'));

  const columns = useMemo(
    () => [
      { Header: "Č. Dokladu", accessor: "doklad", Footer: "CELKEM"  },
      { Header: "Datum vystavení", accessor: "datum_vys" },
      { Header: "Datum UZP", accessor: "datum_uzp" },
      { Header: "Č. Rezervace", accessor: "id_rezervace" },
      { Header: "Platnost OD", accessor: "platnost_od" },
      { Header: "Platnost DO", accessor: "platnost_do" },
      { 
        Header: "ZÁK. 0", 
        accessor: "zak_0",
        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.
            values.zak_0 + sum, 0),
            [info.rows]
          );
          return <> {Math.round(total * 100)/100}</>;
        }  
      },
      { 
        Header: "ZÁK. N.", 
        accessor: "zak_n",
        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.
            values.zak_n + sum, 0),
            [info.rows]
          );
          return <> {Math.round(total * 100)/100}</>;
        }  
      },
      { 
        Header: "DPH N.", 
        accessor: "dph_n", 
        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.
            values.dph_n + sum, 0),
            [info.rows]
          );
          return <> {Math.round(total * 100)/100}</>;
        } 
      },
      { 
        Header: "ZÁK. V.", 
        accessor: "zak_v",
        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.
            values.zak_v + sum, 0),
            [info.rows]
          );
          return <> {Math.round(total * 100)/100}</>;
        }  
      },
      { 
        Header: "DPH V.", accessor: "dph_v", 
        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.
            values.dph_v + sum, 0),
            [info.rows]
          );
          return <> {Math.round(total * 100)/100}</>;
        } 
      },
      { 
        Header: "CELKEM KČ", 
        accessor: "celkem", 
        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.
            values.celkem + sum, 0),
            [info.rows]
          );
          return <> {Math.round(total * 100)/100}</>;
        }
      },
      { Header: "Tel", accessor: "tel" },
      { Header: "Email", accessor: "email" },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const { globalFilter} = state

  const fetchData = async () => {
    
    
    setLoading(true);
    try {
      const dOd = new Date(datumOd).toLocaleDateString('zh-Hans-CN');
      const dDo = new Date(datumDo).toLocaleDateString('zh-Hans-CN');
      if (typeof(datumOd) == 'undefined' || datumOd == null || typeof(datumDo) == 'undefined' || datumDo == null || dOd == 'Invalid Date' || dDo == 'Invalid Date') {
              alert("Špatně zadané datum!");
                                   
      } else {
              const requestOptions = {
                method: 'GET',
                headers: authHeader()
              };
              const stav = 2;
              const apiurl = process.env.REACT_APP_API_URL+"/api/private/prehledy/historie/"+user.u_fi+"/"+stav+"/"+"?datumOD=" + dOd + " 00:00:00&datumDO=" + dDo + " 23:59:00";
              console.log(apiurl);
              const response = await fetch( apiurl, requestOptions);
              const apidata = await response.json();
              setData(apidata);       
      }
      
      
    } catch (error) {

      console.error("Error fetching data:", error);
    } 
    
    
    setLoading(false)
    
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    
    fetchData();
  }

  function handlePrintDoklad(cisdok) {
    //setDoklad(cisdok);
    console.log(cisdok);
    console.log(user.u_fi);
    const requestOptions = {
      method: 'POST',
      headers: authHeader()
      
    };
    return fetch(`${process.env.REACT_APP_API_URL}/api/private/printdoklad/`+user.u_fi+`/`+cisdok+``, requestOptions).then(handleResp(cisdok));   
  }

  function handleResp(cisdok) {
    const pdfWindow = window.open();
    //pdfWindow.location.href = 'c:/WinFofrs/WF_FCamp/nodeserver/doklady/D'+cisdok+'.html';  
    pdfWindow.location.href = 'https://e-kemp.eu/nodeserver/doklady/D'+cisdok+'.html';   
    console.log("POSTED");  
  }

  function handleResp(cisdok) {
    const pdfWindow = window.open();
    //pdfWindow.location.href = 'c:/WinFofrs/WF_FCamp/nodeserver/doklady/D'+cisdok+'.html';  
    pdfWindow.location.href = 'https://e-kemp.eu/nodeserver/doklady/D'+cisdok+'.html';   
    console.log("POSTED");  
  }

  function handleExcelClick(e) {
    e.preventDefault();
    const dOd = new Date(datumOd).toLocaleDateString('zh-Hans-CN');
    const dDo = new Date(datumDo).toLocaleDateString('zh-Hans-CN');
    console.log(data);
    if (typeof(data) == 'undefined' || data.length <= 0) {
      alert("Špatně zvolená data!");  
    } else {  
      
      

      var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(data);

      XLSX.utils.book_append_sheet(wb, ws, "Doklady");
      XLSX.writeFile(wb, "doklady_"+dOd+"_az_"+dDo+".xlsx")
    }  
  }

  //useEffect(() => {
    //fetchData();
  //}, []);
  
  return (
    <>
      
      <div className="containerInputs">
      <h1 className="lblPrehled"> S2 - Přehled Dokladů</h1>   
      <form className="formInputs" onSubmit={handleFormSubmit} >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                <DatePicker 
                  className="datumOD"
                  label="Platnost OD"
                  //value={value}
                  onChange={(newValue) => setDatumOd(newValue)}
                  required="required"
                  format="DD/MM/YYYY"
                />
                <DatePicker
                  className="datumDOArch"
                  label="Platnost DO"
                  //value={value}
                  minDate={datumOd}
                  onChange={(newValue) => setDatumDo(newValue)}
                  required="required"
                  format="DD/MM/YYYY"
                />
                </LocalizationProvider>      
        <button className="btnInputSub" type="submit" >Potvrdit</button>
        <button className="btnExcel" onClick={handleExcelClick}>
            <SiMicrosoftexcel />
        </button>
      </form>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
    <div className="container">
        
      {
      loading ?
          <div id="tablespinner" className="spinner-border"></div>
          :                                                         
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                  <th className="thoperace"><FaWrench className="iconWrench"/></th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                    <td className="colPrint"><Tooltip title="Tisk.." arrow><a className="btnPrint" onClick={() => handlePrintDoklad(row.original.doklad)}><FaPrint /></a></Tooltip></td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>{column.render("Footer")}</td>
                  ))}
                  <td></td>
                </tr>
              ))}
            </tfoot>
          </table>
      }
    </div>
    </>
  );
  
};

export default TableArchiv;
