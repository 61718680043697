import React, { useMemo, useState, useEffect  } from "react";
import { useTable, useSortBy, useGlobalFilter} from "react-table";
import "./TableOsoba.css";
import GlobalFilter from "../_components/GlobalFilter"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { authHeader } from '../_helpers';


const TableOsoba = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [ID, setID] = useState();
  const [datumOd, setDatumOd] = useState();
  const [datumDo, setDatumDo] = useState();
  
  
  const columns = useMemo(
    () => [
      { Header: "ID Klienta", accessor: "kl_ID" },
      { Header: "Jméno", accessor: "kl_jmeno" },
      { Header: "Typ", accessor: "kl_typ" },
      { Header: "Email", accessor: "kl_email" },
      { Header: "Tel", accessor: "kl_tel" },
      { Header: "Ulice a č. popisné", accessor: "kl_ulice" },
      { Header: "Město", accessor: "kl_mesto" },
      { Header: "PSČ", accessor: "kl_psc" },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const { globalFilter} = state

  const fetchData = async () => {
    console.time('VolaniAPI');
    
    setLoading(true);
    try {
      
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };
      const user = JSON.parse(localStorage.getItem('user'));
      const apiurl = process.env.REACT_APP_API_URL+"/api/private/klient/"+user.u_fi+"/0";
      console.log(apiurl);
      const response = await fetch( apiurl, requestOptions);
      const apidata = await response.json();
      setData(apidata);
    } catch (error) {
      console.error("Error fetching data:", error);
    } 
    console.timeEnd('VolaniAPI');
    
    setLoading(false)
    
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    
    fetchData();
  }

  
  
  useEffect(() => {
    fetchData();
   
  }, []);

  
  return (
    <>
      
      <div className="containerInputs">
      <h1 className="lblPrehled"> P3 - Přehled Klientů</h1>   
      <form className="formInputs" onSubmit={handleFormSubmit} >   
      </form>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
    <div className="container">
        
      {
      loading ?
          <div id="tablespinner" className="spinner-border"></div>
          :                                                         
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
      }
    </div>
    </>
  );
  
};

export default TableOsoba;
