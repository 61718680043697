import React from 'react'
import { useState, useEffect } from "react"
import "./MoznostiRezervaceForm.css";
import { FaRegCreditCard } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { IoReloadCircle } from "react-icons/io5";
import { AiOutlineClear } from "react-icons/ai";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { authHeader } from '../_helpers';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/cs';

const MoznostiKliceForm = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#EEEDEB',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      
      };

    const [rezervace, setRezervace] = useState({
        zs_Src: '',
        zs_Id: '',
        zs_Pin: '',
        zs_Limit: '',
        zs_Sdate: '',
        zs_Edate: '',
        zs_Name: '',
        zs_Phone: '',
        zs_Mail: '',
        zs_Stav: '',
        zs_Rcp: '',
        zs_Real: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [datumOd, setDatumOd] = useState();
    const [datumDo, setDatumDo] = useState();
    const [mddatumOd, setMdDatumOd] = useState();
    const [mddatumDo, setMdDatumDo] = useState();
    const [akey, setAkey] = useState();
    const [slloaded,setSlloaded]=useState(false);
    const [sloptions,setSloptions]=useState();
    const [kloptions,setKloptions]=useState();
    const [troptions,setTroptions]=useState();
    const [slvalues,setSlvalues]=useState([]);
    const [klvalues,setKlvalues]=useState([]);
    const [trvalues,setTrvalues]=useState([]);
    const [cleared, setCleared] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const firma = JSON.parse(localStorage.getItem('firma'));

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [hstat, setHstat] = useState(1);
    const [hlabel, setHlabel] = useState();
    
    const handleDatumLoad = (event) => {
        //alert("datum zvolen");
        fetchDropDownSl();
    }

    const handleSlSelectChange = (event) => {
        setSloptions(event.target.value);
    }

    const handleKlSelectChange = (event) => {
        setKloptions(event.target.value);
    }

    const handleTrSelectChange = (event) => {
        setTroptions(event.target.value);
    }

    const handleKlSelectSubmit = (e) => {
        if (typeof(kloptions) !== 'undefined' && kloptions != null && kloptions != "default") {
            //setSelectID(options.split(':').pop())
            const selectedID=kloptions.split(':').pop();
            //console.log(selectedID);
            init(selectedID);
            console.log(rezervace);
        }  
    }

    const init = (selectedID) => {

        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
       //fetch("http://localhost:4000/api/klient/"+ selectedID, requestOptions).then((data)=>data.json()).then((val)=>setInputs(val))
       fetch(process.env.REACT_APP_API_URL+'/api/private/klient/'+user.u_fi+'/'+ selectedID, requestOptions)
        .then(response => response.json())
        .then(data => {
            //setInputs(data)
            console.log(data);
            var ID;
            var stav;
            var jmeno;
            var typ;
            var email;
            var tel;
            var ulice;
            var mesto;
            var psc;

            data.map((obj) => {
                return  ID=obj.kl_ID,
                        stav=obj.stav,
                        jmeno=obj.kl_jmeno,
                        typ=obj.kl_typ,
                        email=obj.kl_email, 
                        tel=obj.kl_tel,
                        ulice=obj.kl_ulice,
                        mesto=obj.kl_mesto,
                        psc=obj.kl_psc
            })  
            
            setRezervace({
                zs_Limit: rezervace.zs_Limit,
                zs_Name: jmeno,
                zs_Phone: tel,
                zs_Mail: email
            });
            
        })
        .catch(error => {
            console.log("err")
        });
    }

    const fetchDropDownSl = () => {
        console.log("fetchdropdownSl");
        const dOd = new Date(datumOd).toLocaleDateString('zh-Hans-CN');
        const dDo = new Date(datumDo).toLocaleDateString('zh-Hans-CN');
        if (typeof(datumOd) == 'undefined' || datumOd == null || typeof(datumDo) == 'undefined' || datumDo == null || dOd == 'Invalid Date' || dDo == 'Invalid Date') {
            alert("Pro zobrazení volných sloupků zvolte platné datum!");      
              
        } else {

            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };
            fetch(process.env.REACT_APP_API_URL+"/api/private/zarizeni/1/"+user.u_fi+"?datumOD=" + dOd + " 10:00:00&datumDO=" + dDo + " 10:59:00", requestOptions).then((data)=>data.json()).then((val)=>setSlvalues(val)) 
        }
    }

    const fetchDropDownTr = () => {
        console.log("fetchdropdownTr");
        
        

            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };
            fetch(process.env.REACT_APP_API_URL+"/api/private/tarif/"+user.u_fi, requestOptions).then((data)=>data.json()).then((val)=>setTrvalues(val)) 
        
    }

    const fetchDropDownKl = () => {
        var val = Math.floor(1000 + Math.random() * 3000);
        console.log(val);
        console.log("fetchdropdown");
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        fetch(process.env.REACT_APP_API_URL+"/api/private/klient/"+user.u_fi, requestOptions).then((data)=>data.json()).then((val)=>setKlvalues(val)) 
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true); 
        
        rezervace.zs_Sdate = new Date(datumOd).toLocaleDateString('zh-Hans-CN') + " 10:00";
        rezervace.zs_Edate = new Date(datumDo).toLocaleDateString('zh-Hans-CN') + " 10:00";  

        if (typeof(datumOd) == 'undefined' || datumOd == null || typeof(datumDo) == 'undefined' || datumDo == null || rezervace.zs_Sdate == 'Invalid Date 10:00' || rezervace.zs_Edate == 'Invalid Date 10:00' || sloptions == "default" || typeof(sloptions) == 'undefined') {
            if (sloptions == "default" || typeof(sloptions) == 'undefined' || typeof(troptions) == 'undefined') {
                alert("Zvolte sloupek!");
            } else {alert("Špatně zadané datum!");}
        } else {
            var prac = sloptions.substring(sloptions.indexOf(":")+1);
            var mastersl = prac.substring(0, prac.indexOf("#"));
            rezervace.zs_Id = prac.substring(prac.indexOf("#")+1);
            var prac2 = troptions.substring(troptions.indexOf(":")+1);
            rezervace.zs_Limit = prac2;    
            var pin = Math.floor(1000 + Math.random() * 3000);
            
            rezervace.zs_Pin=pin;
            rezervace.zs_Stav=2;
            rezervace.zs_Real=0;
            rezervace.zs_Src="web";

            //console.log(mastersl);
            //console.log(rezervace);
            //console.log(rezervace.zs_Limit);
            //console.log(akey[0].fi_API);

            const requestOptions = {
                method: 'POST',
                headers: { ...authHeader(), 'Content-Type': 'application/json' },
                body: JSON.stringify(rezervace)
            };
            return fetch(`${process.env.REACT_APP_API_URL}/api/public/newrezervace?key=`+firma[0].fi_API+`X`+mastersl, requestOptions).then(handleResponse); 
        }
    }

    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (response.status === 200) {
                //window.location.reload(false);
                //window.location.href="/moznosti";
                clearForm();
                console.log("POSTED");

            } else {console.log("ERR")}
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    //logout();
                    //location.reload(true);
                }
                if (response.status === 200) {
                    
                    
                }
    
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
    
            return data;
        });
    }

    const clearForm = () => {
        
        
        
        setRezervace({
            zs_Src: '',
            zs_Id: '',
            zs_Pin: '',
            zs_Limit: '',
            zs_Sdate: '',
            zs_Edate: '',
            zs_Name: '',
            zs_Phone: '',
            zs_Mail: '',
            zs_Stav: '',
            zs_Rcp: '',
            zs_Real: ''
        });
        setSloptions();
        setTroptions();
        setSlvalues([]);
        setKlvalues([]);
        setTrvalues([]);
    }

    const handleHromadnaRez = () => {
        //const dOd = new Date(datumOd).toLocaleDateString('zh-Hans-CN');
        //const dDo = new Date(datumDo).toLocaleDateString('zh-Hans-CN');
        
        if (typeof(datumOd) == 'undefined' || datumOd == null || typeof(datumDo) == 'undefined' || datumDo == null) {
            alert("Pro odeslání hromadné rezervace zvolte platné datum!"); 
            
        }  else {
            console.log(sloptions);
            if (sloptions == "default" || typeof(sloptions) == 'undefined') {
                setHstat(1);
                setHlabel('Opravdu odeslat hromadnou rezervaci všech volných sloupku?');
            } else {
                setHstat(2);
                setHlabel('Opravdu odeslat hromadnou rezervaci volných zásuvek v okruhu '+sloptions.substring(0, 2)+'?');
            }

            setMdDatumOd(new Date(datumOd).toLocaleDateString('zh-Hans-CN'));
            setMdDatumDo(new Date(datumDo).toLocaleDateString('zh-Hans-CN'));
            
            handleOpen();
        }  
    }

    const fetchHromadnaRez = () => {
        
        if (hstat==1) {rezervace.zs_Id = "XX#XX";} else {rezervace.zs_Id = sloptions.substring(0, 2);}
        
        rezervace.zs_Limit = 1000;    
        
        rezervace.zs_Pin=9999;
        rezervace.zs_Stav=99;
        rezervace.zs_Real=0;
        rezervace.zs_Src="web";
        
        rezervace.zs_Sdate = new Date(datumOd).toLocaleDateString('zh-Hans-CN') + " 10:00";
        rezervace.zs_Edate = new Date(datumDo).toLocaleDateString('zh-Hans-CN') + " 10:00";  
        
        console.log(rezervace);
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(rezervace)
        };
        handleClose();
        clearForm();
        return fetch(`${process.env.REACT_APP_API_URL}/api/private/hrezervace/`+user.u_fi, requestOptions).then(handleResponse);
        
    }

    useEffect(()=>{
        //fetchDropDownSl();
        //fetchDropDownKl();
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        fetch(process.env.REACT_APP_API_URL+"/api/private/akey/"+user.u_fi, requestOptions).then((data)=>data.json()).then((val)=>setAkey(val)); 
        console.log(user.u_fi);
        
    },[])

    return (
      <>
            <section className='formDatesRz'>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                    <DatePicker 
                    className="datumOD"
                    name="klic_OD"
                    label="Platnost OD"
                    //value={datumOd}
                    disablePast={true}
                    
                    onChange={(newValue) => setDatumOd(newValue)}
                    required="required"
                    format="DD/MM/YYYY"
                    slotProps={{
                        field: { clearable: true, onClear: () => setCleared(true) },
                    }}
                    
                    />
                    <DatePicker
                    className='datumDORz'
                    name="klic_DO"
                    label="Platnost DO"
                    //value={klic.klic_DO}
                    minDate={datumOd}
                    disablePast={true}
                    onChange={(newValue) => setDatumDo(newValue)}
                    required="required"
                    format="DD/MM/YYYY"
                    slotProps={{
                        field: { clearable: true, onClear: () => setCleared(true) },
                    }}
                    />
                    </LocalizationProvider>
                    </section>
                    <select className='selectSloupekRz' onChange={handleSlSelectChange} onClick={fetchDropDownSl}>
                        <option value="default">Zvolte sloupek..</option>
                        {
                            slvalues.map((opts,i)=><option key={i}>{opts.zname + " :" +opts.z_par}</option>)
                        }
                    </select>
                        
                    <select className='inputLimitRz' onChange={handleTrSelectChange} onClick={fetchDropDownTr}>
                        <option value="default">Limit..</option>
                        {
                            trvalues.map((opts,i)=><option key={i}>{opts.cis_pop + "  :" +opts.cis_mj}</option>)
                        }
                    </select>
                        <br></br>
                    <select className='selectKlientRz' onChange={handleKlSelectChange} onClick={fetchDropDownKl}>
                        <option value="default">Zvolte klienta..</option>
                        {
                            klvalues.map((opts,i)=><option key={i}>{opts.kl_jmeno + " :" +opts.kl_ID}</option>)
                        }
                    </select>
                    <button onClick={handleKlSelectSubmit} className='btnNacist'>
                    Načíst
                    </button>    
            <form name="form" onSubmit={handleSubmit}>
                <section id="formInfoRz">
                    <label className='lblRezervace'>
                        <input 
                            type="text" 
                            name="zs_Name" 
                            value={rezervace.zs_Name} 
                            onChange={(e) =>
                                setRezervace({ ...rezervace, zs_Name: e.target.value })
                            } 
                            className='inputKlientRz'
                            required="required"
                            placeholder=" " 
                        />
                        <span>Jméno</span>
                    </label>
                
                
                    <label className='lblRezervace'>
                        <input 
                            type="text" 
                            name="zs_Phone" 
                            value={rezervace.zs_Phone} 
                            onChange={(e) =>
                                setRezervace({ ...rezervace, zs_Phone: e.target.value })
                            } 
                            className='inputTelRz'
                            required="required"
                            placeholder=" "  
                        />
                        <span>Telefon</span>
                    </label>
                    
                
                    <label className='lblRezervace'>
                        <input 
                            type="email" 
                            name="zs_Mail" 
                            value={rezervace.zs_Mail} 
                            onChange={(e) =>
                                setRezervace({ ...rezervace, zs_Mail: e.target.value })
                            }  
                            className='inputEmailRz' 
                            required="required"
                            placeholder=" "
                        />
                        <span>Email</span>
                    </label>
                    
                    <button className='btnRzSubmit' type='submit'>
                    Odeslat
                    </button>
                    <button onClick={clearForm} className='btnClr'>
                        <AiOutlineClear />
                    </button> 
                    <button onClick={handleHromadnaRez} className='btnHrezervace'>
                        Hromadná Rez.
                    </button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <div>
                            <h3>{hlabel}</h3>
                            
                            Datum: {mddatumOd}  -  {mddatumDo}
                            </div> 
                        </Typography>
                        <button className="btnDelPotvrd" onClick={fetchHromadnaRez}>Potvrdit</button>
                        <button className="btnDelZrus" onClick={handleClose}>Zrušit</button>
                        </Box>
                    </Modal>           
                </section>
                
                
                
            </form>
            
      </>
    )
}

export default MoznostiKliceForm;