import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

import { history } from '_helpers';
import { PrivateRoute} from '_components';
import { Home } from 'home';
import { Prehledy } from 'prehledy';
import { Statistiky } from 'statistiky';
import { Moznosti } from 'moznosti';
import { Sprava } from 'sprava';
//import { Obsah } from 'obsah';
import { Login } from 'login';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

export { App };

function App() {
    // init custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Routes>
                    <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>}/>
                    <Route path="/prehledy" element={<PrivateRoute><Prehledy /></PrivateRoute>}/>
                    <Route path="/statistiky" element={<PrivateRoute><Statistiky /></PrivateRoute>}/>
                    <Route path="/moznosti" element={<PrivateRoute><Moznosti /></PrivateRoute>}/>
                    <Route path="/sprava" element={<PrivateRoute><Sprava /></PrivateRoute>}/>
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </LocalizationProvider>    
        </>
    );
}
