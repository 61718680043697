import React from 'react'
import { useMemo, useState, useEffect  } from "react";
import "./MoznostiUzivateleForm.css";
import { authHeader } from '../_helpers';
import TableUziv from "../prehledytab/TableUziv";
import { useTable, useSortBy, useGlobalFilter} from "react-table";
import GlobalFilter from "../_components/GlobalFilterUziv"
import { AiOutlineClear } from "react-icons/ai";

const MoznostiUzivateleForm = () => {
    const [values,setValues]=useState([])
    const [klientData, setKlientData] = useState([]);
    const [options,setOptions]=useState()
    const [fivalues,setFivalues]=useState([]);
    const [fioptions,setFioptions]=useState();
    const [inputs, setInputs] = useState({});
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const firma = JSON.parse(localStorage.getItem('firma'));

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    const [ID, setID] = useState();
    const [datumOd, setDatumOd] = useState();
    const [datumDo, setDatumDo] = useState();
   
    const columns = useMemo(
        () => [
          { Header: "ID", accessor: "id" },
          { Header: "Uživ. jméno", accessor: "username" },
          { Header: "Firma", accessor: "u_fi" },
          { Header: "Typ", accessor: "u_Typ" },
          { Header: "Stav", accessor: "u_Stav" },
          { Header: "Název", accessor: "u_Jmeno" },
          { Header: "Popis", accessor: "u_Pop" }
        ],
        []
      );
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter
      } = useTable({ columns, data }, useGlobalFilter, useSortBy);
    
      const { globalFilter} = state

    const [ruser, setRuser] = useState({
        u_Jmeno: '',
        u_fi: '',
        username: '',
        u_Pop: '',
        u_Stav: '',
        u_Typ: '',
        password: '',
        password2: ''
    });
    
    function handleSubmit(e) {
        e.preventDefault();
            
        if (ruser.password!==ruser.password2) {
          alert("Hesla se neshodují!"); 
        } else {
                //var prac = fioptions.substring(0, fioptions.indexOf(":"));
                ruser.u_fi = ''+firma[0].fi_ID+'';
                
                
                ruser.u_Stav = '1';
                const requestOptions = {
                    method: 'POST',
                    headers: { ...authHeader(), 'Content-Type': 'application/json' },
                    body: JSON.stringify(ruser)
                };
                console.log(ruser);
                //fetchDropDown();
                clearForm();
                return fetch(`${process.env.REACT_APP_API_URL}/users/register`, requestOptions).then(handleResponse);
              }    
    }

    function editKlient() {
      if (ruser.username=="") {
        alert("Prázdný uživatel!"); 
      } else {
        if (ruser.username==user.username) {
          alert("Nelze editovat aktuálně přihlášeného uživatele!");
        } else { 
          if (ruser.password=='') {
            ruser.u_fi = ''+firma[0].fi_ID+'';               
            ruser.u_Stav = '1';
            const requestOptions = {
                method: 'POST',
                headers: { ...authHeader(), 'Content-Type': 'application/json' },
                body: JSON.stringify(ruser)
            };
            console.log(ruser);
            //fetchDropDown();
            clearForm();
            return fetch(`${process.env.REACT_APP_API_URL}/api/private/edituziv`, requestOptions).then(handleResponse);
          } else if (ruser.password!==ruser.password2) {
            alert("Hesla se neshodují!");
          } else {
            ruser.u_fi = ''+firma[0].fi_ID+'';               
            ruser.u_Stav = '1';
            const requestOptions = {
                method: 'POST',
                headers: { ...authHeader(), 'Content-Type': 'application/json' },
                body: JSON.stringify(ruser)
            };
            console.log(ruser);
            //fetchDropDown();
            clearForm();
            return fetch(`${process.env.REACT_APP_API_URL}/api/private/edituziv`, requestOptions).then(handleResponse);
          }
          
        } 
      }  
    }

    function deleteKlient() {
        
            
        
        if (ruser.username==user.username) {
          alert("Nelze vymazat aktuálně přihlášeného uživatele!");
        } else {         
          ruser.u_fi = ''+firma[0].fi_ID+'';      
          ruser.u_Stav = '1';
          const requestOptions = {
              method: 'POST',
              headers: { ...authHeader(), 'Content-Type': 'application/json' },
              body: JSON.stringify(ruser)
          };
          console.log(ruser);
          //fetchDropDown();
          clearForm();
          return fetch(`${process.env.REACT_APP_API_URL}/api/private/deluziv`, requestOptions).then(handleResponse);
        }
    }

    const clearForm = () => {
        
        
      setRuser({
        u_Jmeno: '',
        u_fi: '',
        username: '',
        u_Pop: '',
        u_Stav: '',
        u_Typ: '',
        password: '',
        password2: ''
      });
      setFivalues([]);
      setLoading(true);
      setTimeout(function() {
        fetchData();
        setLoading(false);
      }, 1000); 
    }

    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (response.status === 200) {
                console.log("POSTED")
            } else {console.log("ERR"); alert("Špatně zvolené jméno nebo heslo, zkuste znovu.");}
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    //logout();
                    //location.reload(true);
                }
                if (response.status === 200) {
                    console.log("POSTED")
                }
    
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
    
            return data;
        });
    }

    const fetchData = async () => {
        console.time('VolaniAPI');
        
        setLoading(true);
        try {
          
          const requestOptions = {
            method: 'GET',
            headers: authHeader()
          };
          const user = JSON.parse(localStorage.getItem('user'));
          const apiurl = process.env.REACT_APP_API_URL+"/api/private/klient/"+user.u_fi+"/999";
          console.log(apiurl);
          const response = await fetch( apiurl, requestOptions);
          const apidata = await response.json();
          setData(apidata);
        } catch (error) {
          console.error("Error fetching data:", error);
        } 
        console.timeEnd('VolaniAPI');
        
        setLoading(false)
        
      };
    
      const handleFormSubmit = (event) => {
        event.preventDefault();
        
        
        fetchData();
      }
    
      function handleRowClick(jmeno,typ,popis,uname) {
       // console.log(slpname);
        setRuser({
            u_Jmeno: jmeno,
            u_fi: user.u_fi,
            username: uname,
            u_Pop: popis,
            u_Stav: '',
            u_Typ: typ,
            password: '',
            password: ''
        });
      }
    
    useEffect(()=>{
        //fetchDropDown();
        fetchData();
    },[])

    return (
      <>
            
            <form name="formKlienti" onSubmit={handleSubmit}>
                <section id="formInfo">
                <section className='formHead'>
            </section>        
                        <label className='lblKlienti'>
                            <input 
                                type="text" 
                                name="u_Jmeno" 
                                value={ruser.u_Jmeno} 
                                onChange={(e) =>
                                    setRuser({ ...ruser, u_Jmeno: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required"
                                placeholder=" "
                            />
                            <span>Jméno a příjmení</span>
                        </label>
                        
                        <label className='lblKlienti'>
                            <input 
                                type="text" 
                                name="u_Typ" 
                                value={ruser.u_Typ} 
                                onChange={(e) =>
                                    setRuser({ ...ruser, u_Typ: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required" 
                                placeholder=" "
                            />
                            <span>Typ klienta</span>
                        </label>
                        <label className='lblKlienti'>
                            <input 
                                type='text'
                                name="u_Pop" 
                                value={ruser.u_Pop} 
                                onChange={(e) =>
                                    setRuser({ ...ruser, u_Pop: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required"
                                placeholder=" "
                            /> 
                            <span>Popis</span>
                        </label> 
                          
                        

                        <label className='lblKlienti'>
                            <input 
                                type="text" 
                                name="username" 
                                value={ruser.username} 
                                onChange={(e) =>
                                    setRuser({ ...ruser, username: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required"
                                placeholder=" "
                            />
                            <span>Username</span>
                        </label>

                        <label className='lblKlienti'>
                            <input 
                                type="password" 
                                name="password" 
                                value={ruser.password} 
                                onChange={(e) =>
                                    setRuser({ ...ruser, password: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required"
                                placeholder=" "
                            />
                            <span>Heslo</span>
                        </label>
                        <label className='lblKlienti'>
                            <input 
                                type="password" 
                                name="password2" 
                                value={ruser.password2} 
                                onChange={(e) =>
                                    setRuser({ ...ruser, password2: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required"
                                placeholder=" "
                            />
                            <span>Ověření hesla</span>
                        </label>
                        <section className="formButtons">
                        <button onClick={editKlient} className='btnEdit'>
                            Editovat
                        </button> 
                        <button type='submit' className='btnUzivSubmit'>
                            Přidat
                        </button>
                        <button onClick={deleteKlient} className='btnDelete'>
                            Vymazat
                        </button>  
                        <button onClick={clearForm} className='btnClr'>
                        <AiOutlineClear />
                        </button>       

                </section>  
                </section>
                
            </form>
            <>
      
      <div className="containerInputsUziv">
      <form className="formInputsUziv" onSubmit={handleFormSubmit} >   
      </form>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
    <div className="containerUziv">
        
      {
      loading ?
          <div id="tablespinner" className="spinner-border"></div>
          :                                                         
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td onClick={() => handleRowClick(row.original.u_Jmeno, row.original.u_Typ, row.original.u_Pop, row.original.username)} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
      }
    </div>
    </>  
      </>
    )
}

export default MoznostiUzivateleForm;