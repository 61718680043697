import React, { useState, useEffect } from "react";
import { MainNav, Nav, PrehledyMenu, NapovedaPr} from '_components';
//import PrehledyMenu from "../components/PrehledyMenu"


const Prehledy = () => {
  

  return (
    <div className="body">
        <Nav></Nav>
        <main>
        <MainNav></MainNav>
        <article>
          <NapovedaPr></NapovedaPr> 
            <PrehledyMenu/>
            
            
              
              
            
        </article>
        
        </main>
        <footer>

        </footer>
    </div>
  )
}

export { Prehledy };