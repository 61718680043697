import React, { useMemo, useState, useEffect  } from "react";
import { useTable, useSortBy, useGlobalFilter} from "react-table";
import "./TableKarta.css";
import GlobalFilter from "../_components/GlobalFilter"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


const TableKarta = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [ID, setID] = useState();
  const [datumOd, setDatumOd] = useState();
  const [datumDo, setDatumDo] = useState();
  
  
  const columns = useMemo(
    () => [
      { Header: "Č. karty", accessor: "kar_karta" },
      { Header: "Č. osoby", accessor: "kar_oscislo" },
      { Header: "Stav karty", accessor: "kar_stavkarty" },
      { Header: "Typ karty", accessor: "kar_typkarty" },
      { Header: "typnasob", accessor: "kar_typnasop" },
      { Header: "Platnost", accessor: "platnost" },
      { Header: "Neplatí po", accessor: "kar_neplatipo" },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const { globalFilter} = state

  const fetchData = async () => {
    console.time('VolaniAPI');
    setLoading(true)

    try {     
      const apiurl = "http://localhost:4000/api/private/prehledy/karta?pru_oscislo=" + ID;
      console.log(apiurl);
      const response = await fetch( apiurl );
      const apidata = await response.json();
      setData(apidata);
    } catch (error) {
      console.error("Error fetching data:", error);
    } 
    console.timeEnd('VolaniAPI');
    setLoading(false)
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    
    //fetchData();
  }

  const handleInputChange = (event) => {
    
    setID(event.target.value);
    console.log(ID);
  };
  
  //useEffect(() => {
    //fetchData();
  //}, []);

  
  return (
    <>
      
      <div className="containerInputs">
      <h1 className="lblPrehled"> P2 - Přehled Klíčů</h1>   
      <form className="formInputs" onSubmit={handleFormSubmit} >
              
              <input
                className="u_id"
                type="text"
                name="u_id"
                required="required"
                placeholder="Zadej č. osoby/karty" 
                onChange={handleInputChange}              
              />
              
                
              
              
              <button className="btnInputSub" type="submit" >Potvrdit</button>
      </form>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
    <div className="container">
      {
          loading ?
          <div id="tablespinner" className="spinner-border"></div>
          :                                                         
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
      } 
        
        
      
    </div>
    </>
  );
  
};

export default TableKarta;
