import React, { useMemo, useState, useEffect  } from "react";
import { useTable, useSortBy, useGlobalFilter} from "react-table";
import "./TableStavy.css";
import GlobalFilter from "../_components/GlobalFilter"
import { authHeader } from '../_helpers';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { RiDeleteBin2Fill } from "react-icons/ri";
import { FaWrench } from "react-icons/fa";
import Tooltip from '@mui/material/Tooltip';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#EEEDEB',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,

};


const TableStavy= () => {
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState([]);
  const [akey, setAkey] = useState();
  const [values,setValues]=useState([])
  const [options,setOptions]=useState()
  const [clslpname,setClslpname]=useState()
  const [cljmeno,setCljmeno]=useState()
  const [clpin,setClpin]=useState()
  const [clpar,setClpar]=useState()

  const [params, setParams] = useState({
    ztyp: ''
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = JSON.parse(localStorage.getItem('user'));
  
  const columns = useMemo(
    () => [
      { Header: "Jméno zařízení", accessor: "zname" },
      { Header: "Typ", accessor: "z_typ" },
      { Header: "ID zařízení", accessor: "z_par" },
      { Header: "Platnost OD", accessor: "zs_OD" },
      { Header: "Platnost DO", accessor: "zs_DO" },
      { Header: "Limit (kWh)", accessor: "zs_limit" },
      { Header: "Spotřeba (kWh)", accessor: "zs_real" },
      { Header: "PIN", accessor: "zs_klic" },
      { Header: "Datum Aktivace", accessor: "zs_TS" },
      { Header: "ID klienta", accessor: "zs_klient" },
      { Header: "Jméno", accessor: "kl_jmeno" },
      { Header: "Telefon", accessor: "kl_tel" },
      { Header: "Email", accessor: "kl_email" },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const { globalFilter} = state

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    if (typeof(options) !== 'undefined' && options != null && options != "default") {
      params.ztyp = options;
      fetchData();
    } else {
      alert("Zvolte typ zařízení!");
    }
  }

  const handleSelectChange = (event) => {
    setOptions(event.target.value);
  }

  const handleCheckboxChange = (event) => {
    //if (volne == false) {setVolne(true);} else {setVolne(false);}
    setChecked(event.target.checked);
    console.log(checked);
  }

  function handleDeleteRow(slpname, kljmeno, slID,pin) {
    if (checked != true) {
      setClslpname(slpname);
      setCljmeno(kljmeno);
      setClpin(pin);
      setClpar(slID);
      handleOpen();
    } else {alert("Zvolte typ");}
  }

  function deleteWrapper() {
    fetchDeletePost()
    setLoading(true);
    setTimeout(function() {
      
      fetchData();
      setLoading(false);
    }, 700); 
    handleClose();
    
  }

  const fetchDeletePost = async () => {
    var rezervace = {
      zs_Src: '',
      zs_Id: '',
      zs_Pin: '',
      zs_Sdate: '',
      zs_Edate: '',
      zs_Limit: '',
      zs_Phone: '',
      zs_Email: '',
      zs_Stav: '',
      zs_Rcp: ''
    };

    var mastersl = clpar.substring(0, clpar.indexOf("#"));

    rezervace.zs_Id = clpar.substring(clpar.indexOf("#")+1);
    rezervace.zs_Pin = clpin;
    rezervace.zs_Stav = 8;
    rezervace.zs_Src="web";

    console.log(mastersl);    
    console.log(rezervace.zs_Pin);
    
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(rezervace)
    };
    
    return fetch(`${process.env.REACT_APP_API_URL}/api/public/newrezervace?key=`+akey[0].fi_API+`X`+mastersl, requestOptions).then(handleResponse);  
      
  };

  const fetchData = async () => {
    console.time('VolaniAPI');
    
    setLoading(true);
    try {
      if (checked == true) {var volne="y"} else {var volne="n"}
      const user = JSON.parse(localStorage.getItem('user'));
      const apiurl = process.env.REACT_APP_API_URL+"/api/private/prehledy/stavy/"+user.u_fi+"/"+ params.ztyp+"/"+volne;
      const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
      };

      console.log(apiurl);
      const response = await fetch( apiurl, requestOptions );
      const apidata = await response.json();
      setData(apidata);
    } catch (error) {
      console.error("Error fetching data:", error);
    } 
    console.timeEnd('VolaniAPI');
    
    setLoading(false)
    
  };

  const fetchDropDown = () => {
    console.log("fetchdropdown");
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const user = JSON.parse(localStorage.getItem('user'));
    fetch(process.env.REACT_APP_API_URL+"/api/private/ztyp/"+user.u_fi, requestOptions)
    .then((data)=>data.json())
    .then((val)=>setValues(val)) 
  }

  function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (response.status === 200) {
            console.log("POSTED")
        } else {console.log("ERR")}
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            }
            if (response.status === 200) {
                
                console.log("POSTED")

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
  }
  
  useEffect(()=>{
    //fetchDropDown();
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };
    fetch(process.env.REACT_APP_API_URL+"/api/private/akey/"+user.u_fi, requestOptions).then((data)=>data.json()).then((val)=>setAkey(val)); 
  },[])

  return (
    <>
      
      <div className="containerInputs">
      <h1 className="lblPrehled"> P1 - Přehled Stavů Zařízení</h1>   
      <form className="formInputs" onSubmit={handleFormSubmit} >
              
              <select className='selectZtyp' onChange={handleSelectChange} onClick={fetchDropDown}>
                <option value="default">Zvolte typ zařízení..</option>
                        {
                            values.map((opts,i)=><option key={i}>{opts.z_typ}</option>)
                        }
              </select>
              
              <FormControlLabel control={<Checkbox className="checkbox" onChange={handleCheckboxChange} checked={checked} color="default" sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }} />} label="Volné" labelPlacement="start"/>
              <button className="btnInputSub" type="submit" >Potvrdit</button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h3>Opravdu smazat rezervaci?</h3>
                    
                    {clslpname}
                    <br></br> 
                    PIN: {clpin}, {cljmeno}
                  </Typography>
                  <button className="btnDelPotvrd" onClick={deleteWrapper}>Potvrdit</button>
                  <button className="btnDelZrus" onClick={handleClose}>Zrušit</button>
                </Box>
              </Modal>
              
      </form>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
      <div className="container">
          
        {
        loading ?
            <div id="tablespinner" className="spinner-border"></div>
            :                                                         
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>               
                    ))}
                    <th className="thoperace"><FaWrench className="iconWrench"/></th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} >
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>                      
                      ))}
                      
                      <td><Tooltip title="Odstranit.." arrow><a className="btnDel" onClick={() => handleDeleteRow(row.original.zname, row.original.kl_jmeno, row.original.z_par, row.original.zs_klic)}><RiDeleteBin2Fill /></a></Tooltip></td>
                      
                    </tr>
                  );
                })}
              </tbody>
            </table>
        }
      </div>
    </>
  );
  
};

export default TableStavy;
