import React from 'react'
import { useState, useEffect } from "react"
import "./SpravaMenu.css";
import { FaRegCreditCard } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import MoznostiKliceForm from "./MoznostiKliceForm";
import MoznostiKlientiForm from "./MoznostiKlientiForm";
import MoznostiZarizeniForm from "./MoznostiZarizeniForm";
import MoznostiUzivateleForm from "./MoznostiUzivateleForm";
import MoznostiRezervaceForm from "./MoznostiRezervaceForm";

const SpravaMenu = () => {
  const [tprehled, setTprehled] = useState("zarizeni")
  const user = JSON.parse(localStorage.getItem('user'));

  const zarizeniKlikHandler = () => {
    setTprehled("zarizeni");
    console.log(tprehled)
  }

  const uzivateleKlikHandler = () => {
    setTprehled("uzivatele");
    console.log(tprehled)
  }

  

  
  if (tprehled=="zarizeni") {
    
    return (
      <>
      
      <div className='flex-container'>
          <div  className='mnu-zarizeni' onClick={zarizeniKlikHandler}>Zařízení</div>
          <div  className='mnu-uziv' onClick={uzivateleKlikHandler}>Uživatelé</div>
          
      </div>
      <h1 className='lblMain'>SP1 - zařízení</h1>
      <MoznostiZarizeniForm  />
      </>
      
    )
  } 
  if (tprehled=="uzivatele") {
    
    return (
      <>
      
      <div className='flex-container'>
          <div  className='mnu-zarizeni' onClick={zarizeniKlikHandler}>Zařízení</div>
          <div  className='mnu-uziv' onClick={uzivateleKlikHandler}>Uživatelé</div>
          
      </div>
      <h1 className='lblMain'>SP2 - uživatelé</h1>
      <MoznostiUzivateleForm  />
      </>
      
    )
  } 
  
}

export { SpravaMenu };