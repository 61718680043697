import React from 'react'
import { useState, useEffect } from "react"
import "./PrehledyMenu.css";
import { FaRegCreditCard } from "react-icons/fa";
import { MdOutlineElectricalServices } from "react-icons/md";
import { FaHourglassHalf } from "react-icons/fa";
import { PiNewspaperClipping } from "react-icons/pi";
import { LuCable } from "react-icons/lu";
import { FaUser } from "react-icons/fa";
import TableStavy from "../prehledytab/TableStavy";
import TableKarta from "../prehledytab/TableKarta";
import TableKlient from "../prehledytab/TableOsoba";
import TableArchiv from "../prehledytab/TableArchiv";
import TableDoklady from "../prehledytab/TableDoklady";
import ChildComp from "./ChildComp";
import Tooltip from '@mui/material/Tooltip';

const PrehledyMenu = () => {
  const [tprehled, setTprehled] = useState("stavy")
  //var tprehled ="osoba";

  const stavyKlikHandler = () => {
    setTprehled("stavy");
    console.log(tprehled)
  }

  const kartaKlikHandler = () => {
    setTprehled("karta");
    console.log(tprehled)
  }

  const klientKlikHandler = () => {
    setTprehled("klient");
    console.log(tprehled)
  }

  /*
  const getChildDataOd = (childdata) => {
    console.log("OD Coming from child OD", childdata)
  } 

  const getChildDataDo = (childdata) => {
    console.log("DO Coming from child DO", childdata)
  } 
  */
  

  if (tprehled=="stavy") {
    
    return (
      <>
      <div className='flex-containerPR'>
          <Tooltip title="Stavy.." arrow>
            <div className='mnu-stavy' onClick={stavyKlikHandler}><MdOutlineElectricalServices /></div>
          </Tooltip>
          <Tooltip title="Karty.." arrow>
            <div className='mnu-karta' onClick={kartaKlikHandler}><FaRegCreditCard /></div>
          </Tooltip>
          <Tooltip title="Klienti.." arrow>
            <div className='mnu-klient' onClick={klientKlikHandler}><FaUser /></div>
          </Tooltip>
      </div>
      {/*<ChildComp onSubmitOd={getChildDataOd} onSubmitDo={getChildDataDo}  />*/}
      <TableStavy  />
      </>
    )
  } 

  if (tprehled=="karta") {
    
    return (
      <>
      <div className='flex-containerPR'>
          <Tooltip title="Stavy.." arrow>
            <div className='mnu-stavy' onClick={stavyKlikHandler}><MdOutlineElectricalServices /></div>
          </Tooltip>
          <Tooltip title="Karty.." arrow>
            <div className='mnu-karta' onClick={kartaKlikHandler}><FaRegCreditCard /></div>
          </Tooltip>
          <Tooltip title="Klienti.." arrow>
            <div className='mnu-klient' onClick={klientKlikHandler}><FaUser /></div>
          </Tooltip>  
      </div>
      <TableKarta  />
      </>
      
    )
  }

  if (tprehled=="klient") {
    
    return (
      <>
      <div className='flex-containerPR'>
          <Tooltip title="Stavy.." arrow>
            <div className='mnu-stavy' onClick={stavyKlikHandler}><MdOutlineElectricalServices /></div>
          </Tooltip>
          <Tooltip title="Karty.." arrow>
            <div className='mnu-karta' onClick={kartaKlikHandler}><FaRegCreditCard /></div>
          </Tooltip>
          <Tooltip title="Klienti.." arrow>
            <div className='mnu-klient' onClick={klientKlikHandler}><FaUser /></div>
          </Tooltip>
      </div>
      {/*<ChildComp onSubmitOd={getChildDataOd} onSubmitDo={getChildDataDo}  />*/}
      <TableKlient  />
      </>
    )
  } 

}

export { PrehledyMenu };