import React, { useState, useEffect } from "react";
import "./NapovedaPr.css"
import { MdQuestionMark } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: '#EEEDEB',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const NapovedaPr = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
        <Tooltip title="Nápověda.." arrow>
            <button id='btnHelp' onClick={handleOpen}>
                <MdQuestionMark />
            </button>
        </Tooltip>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="modalPr">
                <IoClose className="btnCloseMod" onClick={handleClose}/> 
                <h2>
                    Nápověda - Přehledy
                </h2>
                 
                
                
                    <div className="helpOdstavec">
                        <b>P1 - Přehled Stavů Zařízení:</b>
                            <br></br>
                            * slouží k zobrazení všech aktuálních rezervací/stavů dle typu zařízení  
                            <br></br>  
                            vstupní parametry:
                            <ul>
                                <li>typ zařízení</li>
                                <li>volné (zobrazí pouze aktuálně nezabrané zařízení)</li>
                            </ul>
                    </div>
                    <div className="helpOdstavec">
                        <b>P2 - Přehled všech klíčů:</b> 
                        <br></br> 
                            * slouží k zobrazení všech aktuálních klíčů/karet  
                    </div>
                    <div className="helpOdstavec">
                        <b>P3 - Přehled všech klientů:</b> 
                        <br></br> 
                            * slouží k zobrazení všech registrovaných klientů   
                    </div>           
                
            </Box>
        </Modal>
        </>
    )
}

export { NapovedaPr };