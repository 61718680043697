import React from 'react'
import { useState, useEffect } from "react"
import "./MoznostiKlientiForm.css";
import { authHeader } from '../_helpers';
import { AiOutlineClear } from "react-icons/ai";

const MoznostiKlientiForm = () => {
    const [values,setValues]=useState([])
    const [klientData, setKlientData] = useState([]);
    const [options,setOptions]=useState()
    const [inputs, setInputs] = useState({});
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    const user = JSON.parse(localStorage.getItem('user'));
   
    const [klient, setKlient] = useState({
        kl_ID: '',
        kl_stav: '',
        kl_jmeno: '',
        kl_typ: '',
        kl_email: '',
        kl_tel: '',
        kl_ulice: '',
        kl_mesto: '',
        kl_psc: ''
    });

    const [ruser, setRuser] = useState({
        u_Jmeno: 'Test klient',
        u_fi: '2',
        username: 'reg',
        u_Pop: 'xxxx',
        u_Stav: '1',
        u_Typ: 'ADM',
        password: 'xxtomb1234'

    });
    
    // reset login status
    function handleChange(e) {
        const { name, value } = e.target;
        setKlient(klient => ({ ...klient, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        //if ((klic.klic_ID && klic.klic_klient && klic.klic_skup && klic.klic_OD && klic.klic_DO) ) {
           
            klient.kl_tel = klient.kl_tel.replace(/\s/g, '');  
            //klient.klient_tel = klient.klient_tel.trim();
            console.log(klient.kl_tel);
            klient.kl_stav = 1;
            const requestOptions = {
                method: 'POST',
                headers: { ...authHeader(), 'Content-Type': 'application/json' },
                body: JSON.stringify(klient)
            };
            
            fetchDropDown();
            clearForm();
            return fetch(`${process.env.REACT_APP_API_URL}/api/private/newklient/`+user.u_fi, requestOptions).then(handleResponse);
            
        //} 
    }

    function deleteKlient() {
        
        //if ((klic.klic_ID && klic.klic_klient && klic.klic_skup && klic.klic_OD && klic.klic_DO) ) {
           
            
            klient.kl_stav = 9;
            const requestOptions = {
                method: 'POST',
                headers: { ...authHeader(), 'Content-Type': 'application/json' },
                body: JSON.stringify(klient)
            };
            fetchDropDown();
            clearForm();
            return fetch(`${process.env.REACT_APP_API_URL}/api/private/newklient/`+user.u_fi, requestOptions).then(handleResponse);
            
        //} 
    }

    const handleSelectSubmit = (e) => {
        
        
        if (typeof(options) !== 'undefined' && options != null && options != "default") {
            //setSelectID(options.split(':').pop())
            const selectedID=options.split(':').pop();
            //console.log(selectedID);
            init(selectedID);
            fetchDropDown();
            
        }
        
    }

    const clearForm = () => {
        
        
        setKlient({
            kl_ID: '',
            kl_stav: '',
            kl_jmeno: '',
            kl_typ: '',
            kl_email: '',
            kl_tel: '',
            kl_ulice: '',
            kl_mesto: '',
            kl_psc: ''
        });
        setValues([]);
    }

    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (response.status === 200) {
                console.log("POSTED")
            } else {console.log("ERR")}
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    //logout();
                    //location.reload(true);
                }
                if (response.status === 200) {
                    console.log("POSTED")
                }
    
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
    
            return data;
        });
    }
    
    const handleSelectChange = (event) => {
        
        setOptions(event.target.value);
        
        
    }

    

    useEffect(()=>{
        //fetchDropDown();
    },[])

    const fetchDropDown = () => {
        console.log("fetchdropdown");
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        fetch(process.env.REACT_APP_API_URL+"/api/private/klient/"+user.u_fi, requestOptions).then((data)=>data.json()).then((val)=>setValues(val)) 
    }

    const init = (selectedID) => {

            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };
           //fetch("http://localhost:4000/api/klient/"+ selectedID, requestOptions).then((data)=>data.json()).then((val)=>setInputs(val))
           fetch(process.env.REACT_APP_API_URL+'/api/private/klient/'+user.u_fi+'/'+ selectedID, requestOptions)
            .then(response => response.json())
            .then(data => {
                //setInputs(data)
                console.log(data);
                var ID;
                var stav;
                var jmeno;
                var typ;
                var email;
                var tel;
                var ulice;
                var mesto;
                var psc;

                data.map((obj) => {
                    return  ID=obj.kl_ID,
                            stav=obj.stav,
                            jmeno=obj.kl_jmeno,
                            typ=obj.kl_typ,
                            email=obj.kl_email, 
                            tel=obj.kl_tel,
                            ulice=obj.kl_ulice,
                            mesto=obj.kl_mesto,
                            psc=obj.kl_psc
                })  
                
                setKlient({
                    kl_ID: ID,
                    kl_stav: stav,
                    kl_jmeno: jmeno,
                    kl_typ: typ,
                    kl_email: email,
                    kl_tel: tel,
                    kl_ulice: ulice,
                    kl_mesto: mesto,
                    kl_psc: psc
                });
            })
            .catch(error => {
                console.log("err")
            });
        
            
    }
    
    
    return (
      <>
            
            <form name="formKlienti" onSubmit={handleSubmit}>
                <section id="formInfo">
                <section className='formHead'>
            <div className="form-group">   
                <select className='selectKlient' onChange={handleSelectChange} onClick={fetchDropDown}>
                        <option value="default">Zvolte klienta..</option>
                        {
                            values.map((opts,i)=><option key={i}>{opts.kl_jmeno + " :" +opts.kl_ID}</option>)
                        }
                </select>
                <button  onClick={handleSelectSubmit} className='btnNacist'>
                Načíst
                </button>
            </div>
            </section>       
                        <label className='lblKlienti'>
                            <input 
                                type="text" 
                                name="kl_ID" 
                                value={klient.kl_ID} 
                                onChange={(e) =>
                                    setKlient({ ...klient, kl_ID: e.target.value })
                                } 
                                className="inputKlienti" 
                                required="required"
                                placeholder=" "
                            />
                            <span>ID klienta</span>
                        </label>
                        
                        <label className='lblKlienti'>
                            <input 
                                type="text" 
                                name="kl_jmeno" 
                                value={klient.kl_jmeno} 
                                onChange={(e) =>
                                    setKlient({ ...klient, kl_jmeno: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required"
                                placeholder=" "
                            />
                            <span>Jméno a příjmení</span>
                        </label>
                        
                        <label className='lblKlienti'>
                            <input 
                                type="text" 
                                name="kl_typ" 
                                value={klient.kl_typ} 
                                onChange={(e) =>
                                    setKlient({ ...klient, kl_typ: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required" 
                                placeholder=" "
                            />
                            <span>Typ klienta</span>
                        </label>
                        <label className='lblKlienti'>
                            <input 
                                type='email'
                                name="kl_email" 
                                value={klient.kl_email} 
                                onChange={(e) =>
                                    setKlient({ ...klient, kl_email: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required"
                                placeholder=" "
                            /> 
                            <span>Email</span>
                        </label> 
                             
                        <label className='lblKlienti'>
                            <input 
                                type="text" 
                                name="kl_tel" 
                                value={klient.kl_tel} 
                                onChange={(e) =>
                                    setKlient({ ...klient, kl_tel: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required"
                                placeholder=" "
                            />
                            <span>Telefon</span>
                        </label>
                        <section className="formButtonsKl">
                    <button type='submit' className='btnKlientiSubmit'>
                        Odeslat
                    </button> 
                    <button onClick={deleteKlient} className='btnDelete'>
                        Vymazat
                    </button>
                    <button onClick={clearForm} className='btnClr'>
                        <AiOutlineClear />
                    </button> 
                           

                </section>  
                </section>
                
                <section id="formInfo">
                    <div className='formAddressKl'>
                        <label className='lblKlienti'>
                            <input 
                                type="text" 
                                name="kl_ulice" 
                                value={klient.kl_ulice} 
                                onChange={(e) =>
                                    setKlient({ ...klient, kl_ulice: e.target.value })
                                } 
                                className="inputKlienti"
                                placeholder=" "
                            />
                            <span>Ulice a č. p.</span>
                        </label>               
                        <label className='lblKlienti'>
                            <input 
                                type="text" 
                                name="kl_mesto" 
                                value={klient.kl_mesto} 
                                onChange={(e) =>
                                    setKlient({ ...klient, kl_mesto: e.target.value })
                                } 
                                className="inputKlienti"
                                placeholder=" "
                            />
                            <span>Město</span> 
                        </label>                 
                        <label className='lblKlienti'>
                            <input 
                                type="text" 
                                name="kl_psc" 
                                value={klient.kl_psc} 
                                onChange={(e) =>
                                    setKlient({ ...klient, kl_psc: e.target.value })
                                } 
                                className="inputKlienti"
                                placeholder=" "
                            /> 
                            <span>PSČ</span> 
                        </label>
                    </div>         
                </section> 
                
            </form>
              
      </>
    )
}

export default MoznostiKlientiForm;