import React, { useState, useEffect } from "react";
import "./NapovedaEd.css"
import { MdQuestionMark } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const NapovedaEd = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
        <Tooltip title="Nápověda.." arrow>
            <button id='btnHelp' onClick={handleOpen}>
                <MdQuestionMark />
            </button>
        </Tooltip>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="modalEd">
                <IoClose className="btnCloseMod" onClick={handleClose}/> 
                <h2>
                    Nápověda - Editace
                </h2>  
                
                
                    <div className="helpOdstavec">
                            <b>E1 - Rezervace</b>
                            <br></br>
                            * formulář pro zadávání nových rezervací sloupků
                            <br></br>  
                            pole: 
                            <ul>
                                <li>platnost OD-DO (období po které bude rezervace aktivní)</li>
                                <li>sloupek (pro načtení volných sloupků nutno nejprve zvolit platnost)</li>
                                <li>klient (možnost načíst již existujícícho klienta)</li>
                                <li>kontaktní údaje klienta</li>
                            </ul>
                    </div>
                    <div className="helpOdstavec">
                            <b>E2 - Klienti</b>
                            <br></br>
                            * formulář pro zadávání a editaci klientů
                            <br></br>  
                            pole: 
                            <ul>
                                <li>výběr klienta (pokud se jedná o editaci stávajícího klienta, nutno načíst tlačítkem)</li>
                                <li>tlačítko vyčistit (vyprázdní formulář pro znovuzadání, ale neuloží do dat)</li>
                                <li>tlačítko vymazat (odstraní vybraného klienta z dat)</li>
                            </ul>
                    </div>
                    
                    
                            
                
            </Box>
        </Modal>
        </>
    )
}

export { NapovedaEd };