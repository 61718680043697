import React from 'react'
import { useState, useEffect } from "react"
import "./MoznostiMenu.css";
import { FaRegCreditCard } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import MoznostiKliceForm from "./MoznostiKliceForm";
import MoznostiKlientiForm from "./MoznostiKlientiForm";
import MoznostiZarizeniForm from "./MoznostiZarizeniForm";
import MoznostiUzivateleForm from "./MoznostiUzivateleForm";
import MoznostiRezervaceForm from "./MoznostiRezervaceForm";

const MoznostiMenu = () => {
  const [tprehled, setTprehled] = useState("rezervace")
  const user = JSON.parse(localStorage.getItem('user'));

  const kliceKlikHandler = () => {
    setTprehled("klice");
    console.log(tprehled)
  }

  const zarizeniKlikHandler = () => {
    setTprehled("zarizeni");
    console.log(tprehled)
  }

  const klientiKlikHandler = () => {
    setTprehled("klienti");
    console.log(tprehled)
  }

  const uzivateleKlikHandler = () => {
    setTprehled("uzivatele");
    console.log(tprehled)
  }

  const rezervaceKlikHandler = () => {
    setTprehled("rezervace");
    console.log(tprehled)
  }

  if (tprehled=="klice" && user.u_Typ=="ADM") {
    
    return (
      <>
      
      <div className='flex-container'>
          <div  className='mnu-rezervace' onClick={rezervaceKlikHandler}>Rezervace</div>
          <div  className='mnu-klienti' onClick={klientiKlikHandler}>Klienti</div> 
         
          
          
             
      </div>
      <h1 className='lblMain'>E3 - klíče</h1>
      <MoznostiKliceForm  />
      </>
    )
  } else if (tprehled=="klice" && user.u_Typ!="ADM") {
    return (
      <>
      
      <div className='flex-container'>
          
          
          
             
      </div>
      <h1 className='lblMain'>E3 - klíče</h1>
      <MoznostiKliceForm  />
      </>
    )
  }

  if (tprehled=="zarizeni" && user.u_Typ=="ADM") {
    
    return (
      <>
      
      <div className='flex-container'>
          <div  className='mnu-rezervace' onClick={rezervaceKlikHandler}>Rezervace</div>
          <div  className='mnu-klienti' onClick={klientiKlikHandler}>Klienti</div>    
          
          
          
      </div>
      <h1 className='lblMain'>E3 - zařízení</h1>
      <MoznostiZarizeniForm  />
      </>
      
    )
  } 

  if (tprehled=="klienti" && user.u_Typ=="ADM") {
    
    return (
      <>
      
      <div className='flex-container'>
          <div  className='mnu-rezervace' onClick={rezervaceKlikHandler}>Rezervace</div>
          <div className='mnu-klienti' onClick={klientiKlikHandler}>Klienti</div> 
          
          
          
             
      </div>
      <h1 className='lblMain'>E2 - klienti</h1>
      <MoznostiKlientiForm  />
      </>
      
    )
  } else if (tprehled=="klienti" && user.u_Typ!="ADM") {
    return (
      <>
      
      <div className='flex-container'>
          <div  className='mnu-rezervace' onClick={rezervaceKlikHandler}>Rezervace</div>
          <div  className='mnu-klienti' onClick={klientiKlikHandler}>Klienti</div> 
          
          
             
      </div>
      <h1 className='lblMain'>E2 - klienti</h1>
      <MoznostiKlientiForm  />
      </>
    )
  }

  if (tprehled=="rezervace" && user.u_Typ=="ADM") {
    
    return (
      <>
      
      <div className='flex-container'>
          <div  className='mnu-rezervace' onClick={rezervaceKlikHandler}>Rezervace</div>
          <div className='mnu-klienti' onClick={klientiKlikHandler}>Klienti</div> 
          
          
          
             
      </div>
      <h1 className='lblMain'>E1 - zadání rezervace</h1>
      <MoznostiRezervaceForm  />
      </>
      
    )
  } else if (tprehled=="rezervace" && user.u_Typ!="ADM") {
    return (
      <>
      
      <div className='flex-container'>
          <div  className='mnu-rezervace' onClick={rezervaceKlikHandler}>Rezervace</div>
          <div  className='mnu-klienti' onClick={klientiKlikHandler}>Klienti</div> 
          
          
             
      </div>
      <h1 className='lblMain'>E1 - zadání rezervace</h1>
      <MoznostiRezervaceForm  />
      </>
    )
  }

  if (tprehled=="uzivatele" && user.u_Typ=="ADM") {
    
    return (
      <>
      
      <div className='flex-container'>
          <div  className='mnu-rezervace' onClick={rezervaceKlikHandler}>Rezervace</div>
          <div className='mnu-klienti' onClick={klientiKlikHandler}>Klienti</div> 
          
          
          
             
      </div>
      <h1 className='lblMain'>E4 - zadání uživatelů</h1>
      <MoznostiUzivateleForm  />
      </>
      
    )
  }

  
}

export { MoznostiMenu };