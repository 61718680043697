import React from 'react'
import { useMemo,useState, useEffect } from "react"
import "./MoznostiZarizeniForm.css";
import { authHeader } from '../_helpers';
import { useTable, useSortBy, useGlobalFilter} from "react-table";
import GlobalFilter from "../_components/GlobalFilterUziv"
import { AiOutlineClear } from "react-icons/ai";

const MoznostiZarizeniForm = () => {
    const [values,setValues]=useState([])
    const [options,setOptions]=useState()
    const [fivalues,setFivalues]=useState([]);
    const [fioptions,setFioptions]=useState();
    const [inputs, setInputs] = useState({});
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    const user = JSON.parse(localStorage.getItem('user'));
    const [sloptions,setSloptions]=useState();
    const [slvalues,setSlvalues]=useState([]);
    const firma = JSON.parse(localStorage.getItem('firma'));

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
   
    const [zarizeni, setZarizeni] = useState({
        z_jmeno: '',
        z_fi: '',
        z_par: '',
        z_stat: '',
        z_typ: '',
        z_stav: ''
    });

    const columns = useMemo(
        () => [
          { Header: "Jméno zařízení", accessor: "zname" },
          { Header: "Parametr", accessor: "z_par" },
          { Header: "ID", accessor: "z_ID" },
          { Header: "Typ", accessor: "z_typ" }
        ],
        []
      );
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter
      } = useTable({ columns, data }, useGlobalFilter, useSortBy);
    
      const { globalFilter} = state

    // reset login status

    const handleSelectChange = (event) => {
        
        setSloptions(event.target.value);
        
        
    }

    const handleSelectSubmit = (e) => {
        
            if (typeof(sloptions) !== 'undefined' && sloptions != null && sloptions != "default") {
                //setSelectID(options.split(':').pop())
                const selectedID=sloptions.split(':').pop();
                //console.log(selectedID);
                init(selectedID);
                fetchDropDown();
                
            }
        
    }

    const fetchDropDown = () => {
        
        
            //var prac = fioptions.substring(0, fioptions.indexOf(":"));
            zarizeni.z_fi = firma[0].fi_ID;
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };
            fetch(process.env.REACT_APP_API_URL+"/api/private/zarizeni/2/"+zarizeni.z_fi+"?datumOD=2024-01-01 10:00:00&datumDO=2024-12-30 10:59:00", requestOptions).then((data)=>data.json()).then((val)=>setSlvalues(val))
         
    }

    const init = (selectedID) => {
        
            //var prac = fioptions.substring(0, fioptions.indexOf(":"));
            zarizeni.z_fi = firma[0].fi_ID;
            let parametr = selectedID.replace(/#/gi, "-");
            const requestOptions = {
                method: 'GET',
                headers: authHeader(),
            };
        //fetch("http://localhost:4000/api/klient/"+ selectedID, requestOptions).then((data)=>data.json()).then((val)=>setInputs(val))
        fetch(process.env.REACT_APP_API_URL+'/api/private/zarizenidetail/'+zarizeni.z_fi+'/'+parametr, requestOptions)
            .then(response => response.json())
            .then(data => {
                //setInputs(data)
                //console.log(data);
                var jmeno;
                var par;
                var typ;
                var firma;

                data.map((obj) => {
                    return  jmeno=obj.z_jmeno,
                            par=obj.z_par,
                            typ=obj.z_typ,
                            firma=obj.z_fi
                })  
                
                setZarizeni({
                    z_jmeno: jmeno,
                    z_fi: firma,
                    z_par: par,
                    z_stat: '0',
                    z_typ: typ
                    });
            })
            .catch(error => {
                console.log("err")
            });
        
        
}
    const handleFiSelectChange = (event) => {
        setFioptions(event.target.value);
    }
    const fetchDropDownFi = () => {
        


            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };
            fetch(process.env.REACT_APP_API_URL+"/api/private/akey/0", requestOptions).then((data)=>data.json()).then((val)=>setFivalues(val)) 
        
    }

    function handleSubmit(e) {
        e.preventDefault();
            
            //var prac = fioptions.substring(0, fioptions.indexOf(":"));
            zarizeni.z_fi = firma[0].fi_ID;
            zarizeni.z_stat = '0';
            zarizeni.z_stav = '1';
            const requestOptions = {
                method: 'POST',
                headers: { ...authHeader(), 'Content-Type': 'application/json' },
                body: JSON.stringify(zarizeni)
            };
            clearForm();
            fetchData();
            
            return fetch(`${process.env.REACT_APP_API_URL}/api/private/newzarizeni`, requestOptions).then(handleResponse);
        
    }

    function deleteKlient() {
              
        
            zarizeni.z_stav = 9;
            const requestOptions = {
                method: 'POST',
                headers: { ...authHeader(), 'Content-Type': 'application/json' },
                body: JSON.stringify(zarizeni)
            };
            
            clearForm();
            return fetch(`${process.env.REACT_APP_API_URL}/api/private/newzarizeni`, requestOptions).then(handleResponse);
            
            
    }

    const clearForm = () => {
        setZarizeni({
            z_jmeno: '',
            z_fi: '',
            z_par: '',
            z_stat: '',
            z_typ: '',
            z_stav: ''
        });
        setSloptions();
        setFioptions();
        setSlvalues([]);
        setFivalues([]);
        setLoading(true);
        setTimeout(function() {
        fetchData();
        setLoading(false);
      }, 1000); 
    }

    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (response.status === 200) {
                console.log("POSTED")
            } else {console.log("ERR"); alert("Špatně zvolené jméno nebo heslo, zkuste znovu.");}
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    //logout();
                    //location.reload(true);
                }
                if (response.status === 200) {
                    console.log("POSTED")
                }
    
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
    
            return data;
        });
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        
        
        fetchData();
      }

    function handleRowClick(jmeno,typ,parametr) {
        // console.log(slpname);
        setZarizeni({
            z_jmeno: jmeno,
            z_fi: user.u_fi,
            z_par: parametr,
            z_stat: '',
            z_typ: typ,
            z_stav: ''
        }); 
       }

    const fetchData = async () => {
        console.time('VolaniAPI');
        
        setLoading(true);
        try {
          
          const requestOptions = {
            method: 'GET',
            headers: authHeader()
          };
          const user = JSON.parse(localStorage.getItem('user'));
          const apiurl = process.env.REACT_APP_API_URL+"/api/private/zarizeni/2/"+user.u_fi;
          console.log(apiurl);
          const response = await fetch( apiurl, requestOptions);
          const apidata = await response.json();
          setData(apidata);
        } catch (error) {
          console.error("Error fetching data:", error);
        } 
        console.timeEnd('VolaniAPI');
        
        setLoading(false)
        
      };
    
    useEffect(()=>{
        //fetchDropDown();
        fetchData();
    },[])

    return (
      <>
            
            <form name="formKlienti" onSubmit={handleSubmit}>
                <section id="formInfo">
                
                          
                            <select className='selectZarizeni' onChange={handleSelectChange} onClick={fetchDropDown}>
                                <option value="default">Zvolte sloupek..</option>
                                {
                                    slvalues.map((opts,i)=><option key={i}>{opts.zname + " :" +opts.z_par}</option>)
                                }
                            </select>
                            <button  onClick={handleSelectSubmit} className='btnNacist'>
                            Načíst
                            </button>
                                 
                        
                        <label className='lblKlienti'>
                            <input 
                                type="text" 
                                name="z_jmeno" 
                                value={zarizeni.z_jmeno} 
                                onChange={(e) =>
                                    setZarizeni({ ...zarizeni, z_jmeno: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required"
                                placeholder=" "
                            />
                            <span>Název</span>
                        </label>
                        
                        <label className='lblKlienti'>
                            <input 
                                type="text" 
                                name="z_par" 
                                value={zarizeni.z_par} 
                                onChange={(e) =>
                                    setZarizeni({ ...zarizeni, z_par: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required" 
                                placeholder=" "
                            />
                            <span>Parametr</span>
                        </label>
                        <label className='lblKlienti'>
                            <input 
                                type='text'
                                name="z_typ" 
                                value={zarizeni.z_typ} 
                                onChange={(e) =>
                                    setZarizeni({ ...zarizeni, z_typ: e.target.value })
                                } 
                                className="inputKlienti"
                                required="required"
                                placeholder=" "
                            /> 
                            <span>Typ</span>
                        </label> 
                             
                        
                        <section className="formButtons">
                    <button type='submit' className='btnKlientiSubmit'>
                        Odeslat
                    </button>
                    <button onClick={deleteKlient} className='btnDelete'>
                        Vymazat
                    </button>
                    <button onClick={clearForm} className='btnClr'>
                    <AiOutlineClear />
                    </button>       

                </section>  
                </section>
                
            </form>
            <div className="containerInputsUziv">
      <form className="formInputsUziv" onSubmit={handleFormSubmit} >   
      </form>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
    <div className="containerUziv">
        
      {
      loading ?
          <div id="tablespinner" className="spinner-border"></div>
          :                                                         
          <table id="tableZar" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td onClick={() => handleRowClick(row.original.zname, row.original.z_typ, row.original.z_par)} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
      }
    </div>  
      </>
    )
}

export default MoznostiZarizeniForm;