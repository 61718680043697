import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import Header from "../components/Header"
import "./Sprava.css";
import { MainNav, Nav, SpravaMenu} from '_components';
import { userActions } from '_store';

export { Sprava };

function Sprava() {
    
    const user = JSON.parse(localStorage.getItem('user'));
    useEffect(() => {
        //dispatch(userActions.getAll());
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (user.u_Typ == 'TEC') {
        return (
            <div className='body'>
                <Nav />
                <main>
                <MainNav></MainNav>
                <article>
                    
                    <SpravaMenu/>
                       
                </article>
                
                </main>
                <footer>
    
                </footer>
            </div>
        );
    } else {
        return (
            <div className='body'>
                <Nav />
                <main>
                <MainNav></MainNav>
                <article> 
                    <br></br>                
                    <h1>Nemáte dostatečné oprávnění</h1>
                    
                       
                </article>
                
                </main>
                <footer>
    
                </footer>
            </div>
        );
    }

    
}
