import React from 'react'
import "./MainNav.css"
import { FaHome } from "react-icons/fa";
import { FaCalculator } from "react-icons/fa";
import { BsBoxSeamFill } from "react-icons/bs";
import { BsCardList } from "react-icons/bs";
import { FaGlasses } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { Outlet, Link } from "react-router-dom";
import { FaWrench } from "react-icons/fa";

const MainNav = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  
  if (user.u_Typ == 'TEC') {
    return (
      <>
      <nav className='mainNav'>
        <ul className='mnavList'>
          <li className='mnavItem'>
            <Link to="/home">
              <FaHome className='react-icons'/>
              <p className="nav__itemlabel" to="/home">Domů</p>
            </Link>
          </li>
          <li className='mnavItem'>
            <Link to="/prehledy">
              <FaGlasses className='react-icons'/>
              <p className="nav__itemlabel" to="/prehledy">Přehledy</p>
            </Link>
          </li>
          <li className='mnavItem'>
            <Link to="/statistiky">
              <FaCalculator className='react-icons'/>
              <p className="nav__itemlabel" to="/statistiky">Statistiky</p>
            </Link>
          </li>
          <li className='mnavItem'>
            <Link to="/sprava">
              <FaWrench className='react-icons'/>
              <p className="nav__itemlabel" to="/sprava">Správa</p>
            </Link>
          </li>
          <li className='mnavItem'>
            <Link to="/moznosti">
              <IoMdSettings className='react-icons'/>
              <p className="nav__itemlabel" to="/moznosti">Editace</p>
            </Link>
          </li>
          
        </ul>
      </nav>
  
      
      </>
    )
  } else if (user.u_Typ == 'PRA') {
    return (
      <>
      <nav className='mainNav'>
        <ul className='mnavList'>
          <li className='mnavItem'>
            <Link to="/home">
              <FaHome className='react-icons'/>
              <p className="nav__itemlabel" to="/home">Domů</p>
            </Link>
          </li>
          <li className='mnavItem'>
            <Link to="/prehledy">
              <FaGlasses className='react-icons'/>
              <p className="nav__itemlabel" to="/prehledy">Přehledy</p>
            </Link>
          </li>

          
        </ul>
      </nav>
  
      
      </>
    )
  } else {
    return (
      <>
      <nav className='mainNav'>
        <ul className='mnavList'>
          <li className='mnavItem'>
            <Link to="/home">
              <FaHome className='react-icons'/>
              <p className="nav__itemlabel" to="/home">Domů</p>
            </Link>
          </li>
          <li className='mnavItem'>
            <Link to="/prehledy">
              <FaGlasses className='react-icons'/>
              <p className="nav__itemlabel" to="/prehledy">Přehledy</p>
            </Link>
          </li>
          <li className='mnavItem'>
            <Link to="/statistiky">
              <FaCalculator className='react-icons'/>
              <p className="nav__itemlabel" to="/statistiky">Statistiky</p>
            </Link>
          </li>
          <li className='mnavItem'>
            <Link to="/moznosti">
              <IoMdSettings className='react-icons'/>
              <p className="nav__itemlabel" to="/moznosti">Editace</p>
            </Link>
          </li>
          
        </ul>
      </nav>
  
      
      </>
    )  
  }

  
}

export { MainNav };