import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import "./Header.css"
import { FaCampground } from "react-icons/fa";
import { FaCaravan } from "react-icons/fa";
import { authActions } from '_store';
import { authHeader } from '../_helpers';
import { useState, useEffect } from "react"

export { Nav };

function Nav() {
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());
    const user = JSON.parse(localStorage.getItem('user'));
    const [fivalues,setFivalues]=useState([]);
    const [fioptions,setFioptions]=useState();

    const firma = JSON.parse(localStorage.getItem('firma'));
    console.log(user);
    console.log(firma);
    // only show nav when logged in
    if (!authUser) return null;
    

    const fetchDropDown = () => {
        console.log("fetchdropdown");
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        fetch(process.env.REACT_APP_API_URL+"/api/private/ztyp/"+user.u_fi, requestOptions)
        .then(handleResponse)
    }

    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    logout();
                    //location.reload(true);
                }
                
    
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
    
            return data;
        });
    }

    const handleFiSelectChange = (event) => {
        setFioptions(event.target.value);
        
        const profile = JSON.parse(localStorage.getItem('user'));
        
        if (event.target.value == "Zvolte firmu.." || typeof(event.target.value) == 'undefined' || event.target.value == "") {
           var updateduser = {
                createdAt: profile.createdAt,
                id: profile.id,
                u_Jmeno: profile.u_Jmeno,
                u_Pop: profile.u_Pop,
                u_Stav: profile.Stav,
                u_Typ: profile.u_Typ,
                u_fi: "1",
                token: profile.token,
                updatedAt: profile.updatedAt,
                username: profile.username
            };
            localStorage.setItem('user', JSON.stringify(updateduser));    
        } else {
            var prac = event.target.value.substring(0, event.target.value.indexOf(":"));
            var updateduser = {
                createdAt: profile.createdAt,
                id: profile.id,
                u_Jmeno: profile.u_Jmeno,
                u_Pop: profile.u_Pop,
                u_Stav: profile.Stav,
                u_Typ: profile.u_Typ,
                u_fi: prac,
                token: profile.token,
                updatedAt: profile.updatedAt,
                username: profile.username
            };
            localStorage.setItem('user', JSON.stringify(updateduser));
            
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };
            fetch(process.env.REACT_APP_API_URL+"/api/private/akey/"+prac, requestOptions)
            .then((data)=>data.json())
            .then((val)=>localStorage.setItem('firma', JSON.stringify(val)));
 
        }
        
        
        //console.log(profile.u_Jmeno);
        
    }
    const fetchDropDownFi = () => {
        
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };
            fetch(process.env.REACT_APP_API_URL+"/api/private/akey/0", requestOptions).then((data)=>data.json()).then((val)=>setFivalues(val)) 
        
    }

    
    

    
        fetchDropDown();
        /*
        const requestOptions = {
          method: 'GET',
          headers: authHeader()
        };
        fetch(process.env.REACT_APP_API_URL+"/api/private/akey/"+user.u_fi, requestOptions).then((data)=>data.json()).then((val)=>setFivalues(val)); 
        */
        
    
    if (user.u_Typ == 'TEC') {
        return (
            
            <header>
                <a id="headLabel">
                    <i className='lblTitle'><FaCaravan className='faCar'/>  ..FCamp Admin</i>   
                </a>
                
                <button onClick={logout} className="logout">Odhlásit</button> 
                <i className='uziv'> 
                <select className='selectFiNav' required onChange={handleFiSelectChange} onClick={fetchDropDownFi}>
                                <option selected disabled hidden value="default">{firma[0].fi_ID+ " :"+firma[0].fi_jmeno}</option>
                                {
                                    fivalues.map((opts,i)=><option key={i}>{opts.fi_ID + " :" +opts.fi_jmeno}</option>)
                                }
                </select>   
                <br></br>Uživatel: {user.username} 
                </i>   
            </header>
            
        );
    } else {
        return (
        
            <header>
                <a id="headLabel">
                    <i className='lblTitle'><FaCaravan className='faCar'/>  ..FCamp Admin</i>   
                </a>
                  
                <button onClick={logout} className="logout">Odhlásit</button>   
                <i className='uziv'>Firma: {firma[0].fi_jmeno}<br></br>Uživatel: {user.username}</i>  
            </header>
            
        );    
    }
}