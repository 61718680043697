import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import Header from "../components/Header"
import "./Moznosti.css";
import { MainNav, Nav, MoznostiMenu, NapovedaEd} from '_components';
import { userActions } from '_store';

export { Moznosti };

function Moznosti() {
    

    useEffect(() => {
        //dispatch(userActions.getAll());
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='body'>
            <Nav />
            <main>
            <MainNav></MainNav>
            <article>
                <NapovedaEd/>
                <MoznostiMenu/>
                
                   
            </article>
            
            </main>
            <footer>

            </footer>
        </div>
    );
}
