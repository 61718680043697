import React, { useMemo, useState, useEffect  } from "react";
import { useTable, useSortBy, useGlobalFilter} from "react-table";
import "./TableArchiv.css";
import GlobalFilter from "../_components/GlobalFilter"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/cs';
import dayjs from 'dayjs';
import { authHeader } from '../_helpers';


const TableArchiv = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [ID, setID] = useState();
  const [datumOd, setDatumOd] = useState();
  const [datumDo, setDatumDo] = useState();
  
  

  const columns = useMemo(
    () => [
      { Header: "ID zařízení", accessor: "z_par" },
      { Header: "Zařízení", accessor: "z_jmeno" },
      { Header: "PIN", accessor: "ud_klic" },
      { Header: "ID klienta", accessor: "kl_jmeno" },
      { Header: "Klient", accessor: "ud_klient" },
      { Header: "Telefon", accessor: "ud_tel" },
      { Header: "Platnost OD", accessor: "ud_OD" },
      { Header: "Platnost DO", accessor: "ud_DO" },
      { Header: "Limit (kWh)", accessor: "ud_limit" },
      { Header: "Spotřeba (kWh)", accessor: "ud_real" },
      { Header: "Datum vzniku", accessor: "ud_TS" },
      { Header: "Datum smazání", accessor: "ud_TSdel" },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const { globalFilter} = state

  const fetchData = async () => {
    
    
    setLoading(true);
    try {
      const dOd = new Date(datumOd).toLocaleDateString('zh-Hans-CN');
      const dDo = new Date(datumDo).toLocaleDateString('zh-Hans-CN');
      if (typeof(datumOd) == 'undefined' || datumOd == null || typeof(datumDo) == 'undefined' || datumDo == null || dOd == 'Invalid Date' || dDo == 'Invalid Date') {
              alert("Špatně zadané datum!");
              
              
              
      } else {
              const requestOptions = {
                method: 'GET',
                headers: authHeader()
              };
              const user = JSON.parse(localStorage.getItem('user'));
              const stav = 1;
              const apiurl = process.env.REACT_APP_API_URL+"/api/private/prehledy/historie/"+user.u_fi+"/"+stav+"/"+"?datumOD=" + dOd + " 00:00:00&datumDO=" + dDo + " 23:59:00";
              console.log(apiurl);
              const response = await fetch( apiurl, requestOptions);
              const apidata = await response.json();
              setData(apidata);       
      }
      
      
    } catch (error) {
      console.error("Error fetching data:", error);
    } 
    
    
    setLoading(false)
    
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    
    fetchData();
  }

  //useEffect(() => {
    //fetchData();
  //}, []);
  
  return (
    <>
      
      <div className="containerInputs">
      <h1 className="lblPrehled"> S1 - Přehled Archivních Rezervací</h1>   
      <form className="formInputs" onSubmit={handleFormSubmit} >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                <DatePicker 
                  className="datumOD"
                  label="Platnost OD"
                  //value={value}
                  onChange={(newValue) => setDatumOd(newValue)}
                  required="required"
                  format="DD/MM/YYYY"
                />
                
                <DatePicker
                  className="datumDOArch"
                  label="Platnost DO"
                  //value={value}
                  onChange={(newValue) => setDatumDo(newValue)}
                  minDate={datumOd}
                  required="required"
                  format="DD/MM/YYYY"
                />
                </LocalizationProvider>      
        <button className="btnInputSub" type="submit" >Potvrdit</button>
    
      </form>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
    <div className="container">
        
      {
      loading ?
          <div id="tablespinner" className="spinner-border"></div>
          :                                                         
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
      }
    </div>
    </>
  );
  
};

export default TableArchiv;
