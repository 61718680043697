import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import Header from "../components/Header"
import "./Statistiky.css";
import { MainNav, Nav, EditaceMenu, NapovedaSt} from '_components';
import { userActions } from '_store';

export { Statistiky };

function Statistiky() {
    

    useEffect(() => {
        //dispatch(userActions.getAll());
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='body'>
            <Nav />
            <main>
            <MainNav></MainNav>
            <article>
            <NapovedaSt></NapovedaSt>
            <EditaceMenu/>
                   
            </article>
            
            </main>
            <footer>

            </footer>
        </div>
    );
}
