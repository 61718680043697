import React from 'react'

const GlobalFilterUziv = ({filter, setFilter}) => {
  return (
    <span>
        <input className='inputHledejUziv' placeholder='Hledaný výraz' value={filter || ''} onChange={(e) => setFilter(e.target.value)} />
    </span>
  )
}

export default GlobalFilterUziv