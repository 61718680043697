import React from 'react'
import { useState, useEffect } from "react"
import "./EditaceMenu.css";
import { FaRegCreditCard } from "react-icons/fa";
import { MdOutlineElectricalServices } from "react-icons/md";
import { FaHourglassHalf } from "react-icons/fa";
import { PiNewspaperClipping } from "react-icons/pi";
import { LuCable } from "react-icons/lu";
import { FaUser } from "react-icons/fa";
import TableStavy from "../prehledytab/TableStavy";
import TableArchiv from "../prehledytab/TableArchiv";
import TableDoklady from "../prehledytab/TableDoklady";
import ChildComp from "./ChildComp";
import Tooltip from '@mui/material/Tooltip';

const EditaceMenu = () => {
  const [tprehled, setTprehled] = useState("archiv")
  //var tprehled ="osoba";

  const stavyKlikHandler = () => {
    setTprehled("stavy");
    console.log(tprehled)
  }

  const archivKlikHandler = () => {
    setTprehled("archiv");
    console.log(tprehled)
  }

  const dokladKlikHandler = () => {
    setTprehled("doklad");
    console.log(tprehled)
  }

  
  /*
  const getChildDataOd = (childdata) => {
    console.log("OD Coming from child OD", childdata)
  } 

  const getChildDataDo = (childdata) => {
    console.log("DO Coming from child DO", childdata)
  } 
  */

  if (tprehled=="archiv") {
    
    return (
      <>
      <div className='flex-containerPR'>
          <Tooltip title="Archiv.." arrow>
            <div className='mnu-archiv' onClick={archivKlikHandler}><FaHourglassHalf /></div>
          </Tooltip>
          <Tooltip title="Doklady.." arrow>
            <div className='mnu-doklad' onClick={dokladKlikHandler}><PiNewspaperClipping /></div>
          </Tooltip>  
      </div>
      {/*<ChildComp onSubmitOd={getChildDataOd} onSubmitDo={getChildDataDo}  />*/}
      <TableArchiv  />
      </>
    )
  } 

  if (tprehled=="doklad") {
    
    return (
      <>
      <div className='flex-containerPR'>
          <Tooltip title="Archiv.." arrow>
            <div className='mnu-archiv' onClick={archivKlikHandler}><FaHourglassHalf /></div>
          </Tooltip>
          <Tooltip title="Doklady.." arrow>
            <div className='mnu-doklad' onClick={dokladKlikHandler}><PiNewspaperClipping /></div>
          </Tooltip>   
      </div>
      {/*<ChildComp onSubmitOd={getChildDataOd} onSubmitDo={getChildDataDo}  />*/}
      <TableDoklady  />
      </>
    )
  } 
  
}

export { EditaceMenu };