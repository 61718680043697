import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import Header from "../components/Header"
import "./Home.css";
import { MainNav, Nav} from '_components';
import { userActions } from '_store';
import { GiCampingTent } from "react-icons/gi";

export { Home };

function Home() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector(x => x.auth);
    const { users } = useSelector(x => x.users);

    useEffect(() => {
        //dispatch(userActions.getAll());
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='body'>
            <Nav />
            <main>
            <MainNav></MainNav>
            <article>
                <h1 className='lblMain'>Domovská stránka</h1>
                <br></br>
                <br></br>
                <br></br>   
                <div className='popisNav'>
                <h2><GiCampingTent /> - aplikace Fcamp Admin sloužící pro online správu kempů, tábořišť a parkovacích ploch.</h2> 
                <br></br> 
                <br></br>   
                <br></br> 
                <br></br> 
                <br></br>    
                <h4>-  <b>Přehledy</b>: nabídka všech přehledů o aktuálních datech a stavech systému.. </h4>
                <br></br>
                <h4>-  <b>Statistiky</b>: souhrn přehledů statistických a historických dat.. </h4>
                <br></br>
                <h4>-  <b>Editace</b>: menu všech možných operací s daty, vytváření rezervací, vkládání a editace klientů atd.. </h4>
                </div>
            </article>
            
            </main>
            <footer>

            </footer>
        </div>
    );
}
