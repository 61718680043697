import React from 'react'
import { useState, useEffect } from "react"
import "./MoznostiKliceForm.css";
import { FaRegCreditCard } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { authHeader } from '../_helpers';

const MoznostiKliceForm = () => {
    const [klic, setKlic] = useState({
        klic_ID: '',
        klic_klient: '',
        klic_skup: '',
        klic_OD: '',
        klic_DO: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [datumOd, setDatumOd] = useState();
    const [datumDo, setDatumDo] = useState();
    
    // reset login status
    function handleChange(e) {
        const { name, value } = e.target;
        setKlic(klic => ({ ...klic, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true); 
        /*
        klic.klic_OD = new Date(datumOd).toLocaleDateString('zh-Hans-CN');
        klic.klic_DO = new Date(datumDo).toLocaleDateString('zh-Hans-CN');   

        if ((klic.klic_ID && klic.klic_klient && klic.klic_skup && klic.klic_OD && klic.klic_DO) ) {
            console.log(klic);
            const requestOptions = {
                method: 'POST',
                headers: { ...authHeader(), 'Content-Type': 'application/json' },
                body: JSON.stringify(klic)
            };
        
            return fetch(`${"http://localhost:4000"}/api/newklic?key=abc`, requestOptions).then(handleResponse);
            
        } 
        */
        console.log(klic);
        setKlic({
            klic_ID: '',
            klic_klient: '',
            klic_skup: '',
            klic_OD: '',
            klic_DO: ''
        });
    }

    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    //logout();
                    //location.reload(true);
                }
    
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
    
            return data;
        });
    }

    return (
      <>
            <form name="form" onSubmit={handleSubmit}>
                <section id="formInfo">
                    <label className='lblKlic'>
                        <input 
                            type="text" 
                            name="klic_ID" 
                            value={klic.klic_ID} 
                            onChange={(e) =>
                                setKlic({ ...klic, klic_ID: e.target.value })
                            } 
                            className='inputKlic'
                            required="required"
                            placeholder=" " 
                        />
                        <span>ID klíče</span>
                    </label>
                
                
                    <label className='lblKlic'>
                        <input 
                            type="text" 
                            name="klic_klient" 
                            value={klic.klic_klient} 
                            onChange={(e) =>
                                setKlic({ ...klic, klic_klient: e.target.value })
                            } 
                            className='inputKlic'
                            required="required"
                            placeholder=" "  
                        />
                        <span>ID klienta</span>
                    </label>
                    
                
                    <label className='lblKlic'>
                        <input 
                            type="text" 
                            name="klic_skup" 
                            value={klic.klic_skup} 
                            onChange={(e) =>
                                setKlic({ ...klic, klic_skup: e.target.value })
                            }  
                            className='inputKlic' 
                            required="required"
                            placeholder=" "
                        />
                        <span>Skupina</span>
                    </label>
                    <section className='formDates'>
                    <DatePicker 
                    className="datumOD"
                    name="klic_OD"
                    label="Platnost OD"
                    //value={klic.klic_OD}
                    disablePast={true}
                    
                    onChange={(newValue) => setDatumOd(newValue)}
                    required="required"
                    format="DD/MM/YYYY"
                    
                    />
                    <DatePicker
                    className='datumDOX'
                    name="klic_DO"
                    label="Platnost DO"
                    //value={klic.klic_DO}
                    disablePast={true}
                    onChange={(newValue) => setDatumDo(newValue)}
                    required="required"
                    format="DD/MM/YYYY"
                    
                    />
                    </section>
                    <button className='btnKlicSubmit' type='submit'>
                    Odeslat
                    </button>         

                </section>
                
                
                
            </form>
      </>
    )
}

export default MoznostiKliceForm;